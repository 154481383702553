<template>
    <main>
        <section>
            <h1>Forge Laser Creations & Design</h1>
            <p>We help individuals, companies and organizations create stunning designs with the aid of our <AppLink to="https://glowforge.com/" newtab>Glowforge</AppLink> laser cutter and engraver.</p>
        </section>
        <section class="color-section">
            <h2>People</h2>
            <p>Our company consists of a two-person, mother-son team with a ton of experience across a wide variety of creative disciplines from digital graphics to greeting cards. With support from our family, we help bring a little more creativity and unique design to our communities.</p>
            <p>We are devoted to our customers and work tirelessly to provide beautiful, useful products to anyone who contacts us with a project in mind. Whether it's a simple engraving on personal item or hundreds of unique <AppLink to="/projects">tokens</AppLink> for advertising or marketing, we put in the effort to make it exactly what you want.</p>
        </section>
        <section>
            <h2>Name</h2>
            <p><i><strong>Forge</strong> Laser Creations &amp; Designs</i> comes from the idea of starting from scratch, putting in hard work, and engaging in the ancient, classic art of craftsmanship. It was also inspired by the name of our first main tool, the <AppLink to="https://glowforge.com" newtab>Glowforge</AppLink>.</p>
            <h2>Community</h2>
            <p>We are proud to be a part of the Granbury Chamber of Commerce, joining other Granbury business owners in making Granbury a great place to own and run a business.</p>
        </section>
        <section class="color-section alt-color-section">
            <h2>This Website & Privacy</h2>
            <p>We believe that privacy is of the utmost importance when it comes to navigating and using services on the web. We <i>DO NOT</i> collect or store any information in any form regarding your activity on this site. The only information we receive will be that which you willingly send us via our fillable forms. We will retain this info only to answer your questions, to fulfill your orders, and to properly charge for sales tax. Your data will never be sold, and will only be shared if it is necessary to fullfil your order and only with your permission. You can request a deletion of your info by sending us a message with our <AppLink to="/contact">contact form</AppLink>.</p>
            <p>Any data requiring temporary storage (such as the items in your cart) is stored and kept locally on your machine. This data, known as a cookie, is stored in your browser. It allows the website to "remember" which items were in your cart the next time you return. You can delete cookies through your browser's settings.</p>
            <AppLink to="https://en.wikipedia.org/wiki/HTTP_cookie" newtab>Learn More</AppLink>
        </section>
        <section>
            <SiteLogo />
            <h4>Est. 2017 | Granbury, Texas</h4>
        </section>
        <section>
            <h3>This Website is Built with:</h3>
            <AppLink to="https://vuejs.org" newtab>Vue 3</AppLink>
            <AppLink to="https://github.com/antoniandre/vueper-slides" newtab>Vueper Slides</AppLink>
            <AppLink to="https://github.com/XiongAmao/vue-easy-lightbox" newtab>vue-easy-lightbox</AppLink>
            <AppLink to="https://fonts.google.com/specimen/Space+Grotesk" newtab>Space Grotesk</AppLink>
            <AppLink to="https://feathericons.com/" newtab>Feather Icons</AppLink>
        </section>
    </main>
</template>

<script>
    /* eslint-disable */
    import SiteLogo from '@/components/SiteLogo.vue'

    export default {
        name: 'About',
        components: {
            SiteLogo
        }
    }
</script>